import React from "react";
import Logo from "../Assets/Images/logo-footer.png";
import PlayStore from "../Assets/Images/play_store.svg";
import AppStore from "../Assets/Images/apple.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TiSocialInstagram } from "react-icons/ti";
import { FaFacebookF } from "react-icons/fa";
import { AppUrls } from "../Constants/urls";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-sm-8 col-md-6 my-lg-0 my-3">
            <img className="logo" src={Logo} alt="Logo" />
            <p>Subscribe to our news letter to get latest updates</p>
            <div className="subscribe-box">
              <input type="email" placeholder="Your Email Address" />
              <Button>Subscribe</Button>
            </div>
            <div className="social-icon">
              <Link to={AppUrls.instagram}>
                <TiSocialInstagram size={30} />
              </Link>
              <Link to={AppUrls.facebook}>
                <FaFacebookF size={30} />
              </Link>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row bg-gray m-2 py-3 rounded-2">
              <div className="col-sm-6 col-md-3 mb-md-0 mb-3">
                <h5>About</h5>
                <ul>
                  <li>
                    <Link to={AppUrls.aboutUs}>About us</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={AppUrls.categories}>Categories</Link>
                  </li>
                </ul>
              </div>

              <div className="col-sm-6 col-md-3 mb-md-0 mb-3">
                <h5>Quick Links</h5>
                <ul>
                  <li>
                    <Link to={AppUrls.moneyRefund}>Money Refunds</Link>
                  </li>
                  <li>
                    <Link to={AppUrls.shippingPolicy}>Shipping</Link>
                  </li>
                  <li>
                    <Link to={AppUrls.cancelationPolicy}>
                      Cancellation Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={AppUrls.privacyPolicy}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={AppUrls.termsConditions}>Terms & Condition</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 mb-md-0 mb-3">
                <h5>For Users</h5>
                <ul>
                  <li>
                    <Link to={AppUrls.login}>Login</Link>
                  </li>
                  <li>
                    <Link to={AppUrls.conversation}>Live Chat</Link>
                  </li>
                  <li>
                    <Link to={AppUrls.myOrder}>My Orders</Link>
                  </li>
                  <li>
                    <Link to={AppUrls.helpSupport}>Help & Support</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 mb-md-0 mb-3">
                <h5>Get App</h5>
                <ul className="mt-4">
                  <li>
                    <a href={AppUrls.playStore}>
                      <img className="store-img" src={PlayStore} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a href={AppUrls.appStore}>
                      <img className="store-img" src={AppStore} alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="m-0">© 2024 munchies</p>
      </div>
    </footer>
  );
}

export default Footer;
