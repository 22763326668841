import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RiSendPlaneLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { AppUrls } from "../Constants/urls";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function BannerSection() {
  const [address, setAddress] = useState("");

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      console.log("Geocode results:", results);
      console.log("Latitude and Longitude:", latLng);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const searchOptions = {
    componentRestrictions: { country: ['FI'] },
    // types: ['city']
  }

  return (
    <>
      <section className="banner-sec">
        <div className="bg-box">
          <Row className="banner-content justify-content-center">
            <Col>
              <h1>Ravintolaruokaa, noutoruokaa ja elintarvikkeita. Toimitettuna.</h1>
              <div className="search-box">
                <p>Anna postinumero nähdäksesi, mitä toimitamme:</p>
                <Form className="d-flex justify-content-center mt-4">
                  <PlacesAutocomplete
                    value={address}
                    searchOptions={searchOptions}
                    onChange={handleChange}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="d-flex flex-column w-100">
                        <div
                          className="d-flex justify-content-center align-items-center w-100"
                          style={{ position: "relative" }}
                        >
                          <RiSendPlaneLine
                            color="#7e1337"
                            size={23}
                            style={{ position: "absolute", left: 16 }}
                          />
                          <Form.Control
                            {...getInputProps({
                              type: "text",
                              placeholder: "e.g. EC4R 3TE",
                              className: "search-input",
                            })}
                          />
                          {loading && <div>Loading...</div>}
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container mx-3 ">
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div key={suggestion.key}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <Link to="https://munchies.fi/">
                    <Button variant="success" className="search-button">
                      Search
                    </Button>
                  </Link>
                </Form>
                <span>
                  {" "}
                  <Link to={AppUrls.login}>Kirjaudu</Link> sisään saadaksesi lisätietoja.
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default BannerSection;
