import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../Assets/Images/logo.png";
import { IoIosBasket } from "react-icons/io";
import { MdOutlineKeyboardArrowDown, } from "react-icons/md";
import { RiAccountCircleFill, RiHome4Line } from "react-icons/ri";
import { FaBicycle } from "react-icons/fa";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { AppUrls } from "../Constants/urls";

function Header() {
  return (
    <header>
      <div className="px-25">
        <Navbar>
          <div className="w-100 d-flex">
            <Navbar.Brand href="#">
              <img src={Logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse
              id="navbarScroll"
              className="justify-content-end align-items-start"
            >
              <Nav
                className="ml-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <NavDropdown
                  title={
                    <div className="d-flex align-items-center">
                      <MdOutlineKeyboardArrowDown color="#7e1337" size={28} />
                      <span className="d-sm-block d-none">
                        {" "}
                        Partner with Us
                      </span>
                    </div>
                  }
                  id="navbarScrollingDropdown"
                  className="p-0 border-0"
                >
                  <NavDropdown.Item href={AppUrls.rider}>
                    <FaBicycle color="#7e1337" size={20} className="me-2" />
                    Riders
                  </NavDropdown.Item>
                  <NavDropdown.Item href={AppUrls.partner}>
                    <IoIosBasket color="#7e1337" size={19} className="me-2" />
                    Partners
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href={AppUrls.login}>
                  <RiHome4Line color="#7e1337" size={18} className="me-md-2" />
                  <span className="d-md-block d-none"> Sign up or log in</span>
                </Nav.Link>
                {/* <Nav.Link href="#action2">
                  <RiAccountCircleFill
                    color="#7e1337"
                    size={18}
                    className="me-md-2"
                  />
                  <span className="d-md-block d-none"> Account</span>
                </Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </header>
  );
}

export default Header;
