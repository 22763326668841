import React from "react";
import BannerSection from "../Components/BannerSection";
import Slider from "react-slick";
import slide1 from "../Assets/Images/slide1.png";
import slide2 from "../Assets/Images/slide2.png";
import slide3 from "../Assets/Images/slide4.png";
import slide4 from "../Assets/Images/slide3.png";
import slide5 from "../Assets/Images/slide5.png";
import slide6 from "../Assets/Images/slide6.png";
import slide7 from "../Assets/Images/slide7.png";
import slide8 from "../Assets/Images/slide8.png";
import slide9 from "../Assets/Images/slide9.png";
import slide10 from "../Assets/Images/slide10.png";
import slide11 from "../Assets/Images/slide11.png";
import slide12 from "../Assets/Images/slide12.png";
import slide13 from "../Assets/Images/slide13.png";
import slide14 from "../Assets/Images/slide14.png";
import slide15 from "../Assets/Images/slide15.png";
import slide16 from "../Assets/Images/slide16.png";
import slide17 from "../Assets/Images/slide17.png";
import Map from "../Assets/Images/map.jpeg";
import Star1 from "../Assets/Images/star1.svg";
import Star2 from "../Assets/Images/star2.svg";
import Partner from "../Assets/Images/partner.jpeg";
import Rider from "../Assets/Images/rider.jpeg";
import Delivery from "../Assets/Images/delivery.jpg";
import GiftCard from "../Assets/Images/giftcard.jpg";
import PlayStore from "../Assets/Images/play_store.svg";
import AppleStore from "../Assets/Images/apple_store.svg";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AppUrls } from "../Constants/urls";

function Home() {
  var settings = {
    slidesToShow: 9,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 19000,
    pauseOnHover: false,
    cssEase: "linear",
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <BannerSection />
      <section className="track-order-sec">
        <div className="container">
          <div className="track-order-box">
            <div className="row">
              <div className="col-md-5 py-4 py-lg-5 px-4 px-lg-5 mb-md-0 mb-4">
                <h2>Lataa Munchies -Food Delivery sovellus</h2>
                <p>
                  Munchies -Food Delivery -sovelluksen avulla saat suosikkiateriat toimitettuna kotiovellesi. 
                  Saatavilla iOS- ja Android-sovelluskaupoissa, lataa nyt ja nauti yksinkertaisimmasta ja saumattomasta tilaamisesta. 
                  Lataamalla saat reaaliaikaisen ruuan seurannan ja tulevat päivitykset, jotka erottavat meidät muista.
                </p>
                <div className="store-btn d-flex align-items-center">
                  <Link to={AppUrls.appStore}>
                    <img
                      className="me-1"
                      style={{ width: "9rem" }}
                      src={AppleStore}
                      alt="img"
                    />
                  </Link>
                  <Link to={AppUrls.playStore}>
                    <img src={PlayStore} style={{ width: "10rem" }} alt="img" />
                  </Link>
                </div>
              </div>
              <div className="col-md-7 position-relative">
                <img className="w-100 h-100" src={Map} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="slider-sec">
        <Slider {...settings}>
          <div className="slide-box">
            <img src={slide1} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide2} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide3} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide4} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide5} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide6} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide7} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide8} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide9} alt="img" />
          </div>
        </Slider>
        <Slider {...settings}>
          <div className="slide-box">
            <img src={slide9} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide10} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide11} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide12} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide13} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide14} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide15} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide16} alt="img" />
          </div>
          <div className="slide-box">
            <img src={slide17} alt="img" />
          </div>
        </Slider>
      </section>

      <section className="deal-box overflow-hidden">
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-xl-8 text-center">
              <div className="d-flex align-items-center justify-content-center">
                <img className="start-img" src={Star1} alt="img" />
                <h2>Up to 25% off meal deals</h2>
                <img className="start-img" src={Star2} alt="img" />
              </div>
              <p className="px-2 px-md-5">
                Craving a midweek treat, a break from cooking, or just in the
                mood for your favorite restaurant?
              </p>
              <span>
                Availability may vary. Only at participating restaurants.
                Service and delivery fees apply
                <Link to={AppUrls.termsConditions}>T&Cs</Link>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="category-sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-xl-4 category-box mb-xl-0 mb-5">
              <img className="w-100" src={Partner} alt="img" />
              <h3>Partner with Munchies </h3>
              <p>
                Expand your reach and connect with more customers by partnering
                with Munchies. We'll take care of the delivery, letting you
                concentrate on creating great food.
              </p>

              <Link to={AppUrls.partner}>
                <Button>Get Started</Button>
              </Link>
            </div>
            <div className="col-sm-6 col-xl-4 category-box mb-xl-0 mb-5">
              <img className="w-100" src={Rider} alt="img" />
              <h3>Ride with Munchies</h3>
              <p>
                Join the Munchies team and enjoy the freedom to work on your own
                schedule. Earn competitive pay while benefiting from exclusive
                perks and discounts. With Munchies, you’re not just delivering
                food—you’re making a difference in your community, all while
                fitting work around your life.
              </p>
              <Link to={AppUrls.rider}>
                <Button>Get Started</Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
